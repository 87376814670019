import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCipRu-p2YV6xerR4vuzH0mwS57ucPE2NA",
  authDomain: "angadi-cafe.firebaseapp.com",
  projectId: "angadi-cafe",
  storageBucket: "angadi-cafe.appspot.com",
  messagingSenderId: "925252871745",
  appId: "1:925252871745:web:f2274e1206f6dfa7a888c0",
  measurementId: "G-PGGDS7RHSX",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export { auth, provider };
