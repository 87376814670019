import React from "react";

function Home() {
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <div>
      <h1>home pagr</h1>
      <button onClick={logout}>log out</button>
    </div>
  );
}

export default Home;
