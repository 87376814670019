import React from "react";
import { auth, provider } from "../googlesignin/Config";
import { signInWithPopup } from "firebase/auth";
import { useState } from "react";
import { useEffect } from "react";
import Home from "./Home";

function Signin() {
  const [value, setvalue] = useState("");
  const handleclick = () => {
    signInWithPopup(auth, provider).then((data) => {
      setvalue(data.user.email);
      localStorage.setItem("email", data.user.email);
    });
  };
  useEffect(() => {
    setvalue(localStorage.getItem("email"));
  });
  return (
    <div>
      {value ? (
        <Home />
      ) : (
        <button onClick={handleclick}>signin with google</button>
      )}
    </div>
  );
}

export default Signin;
